/**
 * Content (objects/_content.scss)
 */

/* ----- Headings ----- */
.content {
	> :first-child {
		margin-top: 0;
	}
}
