/**
 * Nav grid (components/_nav-grid.scss)
 */

/* ----- General ----- */
.nav-grid {
	font-size: .9375em;
	line-height: 1.8em;
	color: $color-dark-blue;
}

.nav-grid--out {
	@media (min-width: $screen-sm-min) {
		margin-top: -11.25rem;
	}
}

/* ----- Item ----- */
.nav-grid__item {
	@include link-reset;
	display: block;
	background-color: $color-theme-blue;
	border: 2px solid rgba($color-dark-blue, .2);
	margin-bottom: .9375rem;
	transition: background-color .2s, border .2s;

	@media (min-width: $screen-sm-min) {
		margin-bottom: 1.875rem;
	}

	&:hover,
	&:active,
	&:focus {
		border-color: $color-dark-blue;

		.nav-grid__btn {
			border-color: $color-dark-blue;

			.icon {
				@include translate(.625rem, 0);
			}
		}
	}
}

/* ----- Content ----- */
.nav-grid__content {
	@include clearfix();
	padding: 1.25rem 1.875rem .625rem 1.875rem;

	@media (min-width: $screen-md-min) {
		padding: 1.875rem 2.5rem 1.25rem 2.5rem;
	}

	p {
		margin-bottom: .625rem;
	}
}

/* ----- Title ----- */
.nav-grid__title {
	display: block;
	font-size: $font-size-h3;
	font-weight: 500;
	padding: 0;
	margin-top: 0;
	border: 0;
}

/* ----- Read more ----- */
.nav-grid__btn {
	font-family: $font-family-secondary;
	font-weight: 500;
	text-transform: uppercase;
	border-top: 2px solid rgba($color-dark-blue, .2);
	padding: 1.25rem 1.875rem 0 1.875rem;
	transition: border .2s;

	@media (min-width: $screen-md-min) {
		padding: 1.25rem 2.5rem 0 2.5rem;
	}

	.icon {
		display: inline-block;
		font-size: 1.25em;
		margin-left: .5rem;
		position: relative;
		bottom: -.125rem;
		transition: transform .2s;
	}
}
