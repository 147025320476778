/**
 * Red (themes/_red.scss)
 */

/* ----- Hero subheader ----- */
.red {
	.hero-subheader {
		background-color: $color-theme-red;
	}
}

/* ----- Nav grid ----- */
.red {
	.nav-grid__item {
		background-color: $color-theme-red;

		&:hover,
		&:active,
		&:focus {
			background-color: $color-theme-red;
		}
	}
}

/* ----- Section ----- */
.red {
	.section--darker-theme-color-bg {
		background-color: darken($color-theme-red, 3%);
	}
}

/* ----- Page nav ----- */
.red {
	.page-nav {
		background-color: $color-theme-red;
	}
}

/* ----- Error 404 ----- */
.red {
	&.error-404 {
		background-color: $color-theme-red;
	}
}
