/**
 * Section (objects/_section.scss)
 */

/* ----- General ----- */
.section {
	padding: 3.75rem 0 (3.75rem - 1.25rem) 0;

	@media (min-width: $screen-sm-min) {
		padding: 6.25rem 0 (6.25rem - 1.25rem) 0;
	}
}

/* ----- Variations ----- */
.section--darker-theme-color-bg {
	background-color: darken($color-theme-blue, 3%);
}

.section--grey {
	background-color: $color-section-grey;
}



.post-content {
	@media (max-width: 768px){
		padding-top: 10px;
		margin-top: -60px;
		border: none;
		border-radius: none;
	}
	padding: 30px;
	border-bottom: 1px dashed #f8f8f8;
	border-radius: 5px;
	background: #fff;
	box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
	border: 1px solid rgba(0,0,0,.125);
	margin-top: -200px;
	position: relative;
	z-index: 3;
}
