/**
 * Prefixer (tools/_prefixer.scss)
 */

/* ----- Box sizing ----- */
@mixin box-sizing ($value) {
	-webkit-box-sizing: $value;
	-moz-box-sizing: $value;
	box-sizing: $value;
}

/* ----- Transform ----- */
// Rotate
@mixin rotate ($deg) {
	-webkit-transform: rotate(#{$deg}deg);
	-moz-transform: rotate(#{$deg}deg);
	-ms-transform: rotate(#{$deg}deg);
	transform: rotate(#{$deg}deg);
}

// Translate
@mixin translate ($x, $y) {
	-webkit-transform: translate($x, $y);
	-moz-transform: translate($x, $y);
	-ms-transform: translate($x, $y);
	transform: translate($x, $y);
}

// Translate 3D
@mixin translate3d ($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
	-moz-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}
