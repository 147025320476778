/**
 * Page nav (components/_page-nav.scss)
 */

/* ----- General ----- */
.page-nav {
	color: $color-dark-blue;
	background-color: $color-theme-blue;
	padding: 1.25rem 0;

	@media (min-width: $screen-sm-min) {
		padding: 2.1875rem 0 2.5rem 0;
	}
}

/* ----- Item ----- */
.page-nav__item {
	@include link-reset;
	display: block;
	text-align: center;
	font-weight: 500;
	font-family: $font-family-secondary;

	@media (min-width: $screen-sm-min) {
		font-size: $font-size-h3;
	}

	& + & {
		border-top: 1px solid rgba($color-dark, .15);
		padding-top: 1.25rem;
		margin-top: 1.25rem;

		@media (min-width: $screen-sm-min) {
			border-top: 0;
			padding-top: 0;
			margin-top: 0;
		}
	}

	.icon {
		display: inline-block;
		position: relative;
		bottom: -.0625rem;
		transition: transform .2s;
	}
}

.page-nav__item--prev {
	@media (min-width: $screen-sm-min) {
		float: left;
	}

	&:hover,
	&:focus,
	&:active {
		.icon {
			@include translate(-.625rem, 0);
		}
	}

	.icon {
		margin-right: .625rem;
	}
}

.page-nav__item--next {
	@media (min-width: $screen-sm-min) {
		float: right;
	}

	&:hover,
	&:focus,
	&:active {
		.icon {
			@include translate(.625rem, 0);
		}
	}

	.icon {
		margin-left: .625rem;
	}
}
