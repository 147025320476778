/**
 * Style (style.scss)
 */

/* ----- Charset ----- */
@charset 'utf-8';

/* ----- Variables ----- */
@import 'variables';

/* ----- Tools ----- */
@import 'tools/prefixer';
@import 'tools/clearfix';
@import 'tools/grid-framework';
@import 'tools/grid';
@import 'tools/hyperlinks';
@import 'tools/lists';
@import 'tools/responsive-visibility';

/* ----- Base ----- */
@import 'generic/normalize';
@import 'generic/base';
@import 'generic/code';

/* ----- Utilities ----- */
@import 'utilities/alignment';
@import 'utilities/visibility';

/* ----- Objects ----- */
@import 'objects/buttons';
@import 'objects/content';
@import 'objects/error-404';
@import 'objects/grid';
@import 'objects/icons';
@import 'objects/section';
@import 'objects/dropdown';

/* ----- Components ----- */
@import 'components/callout';
@import 'components/example';
@import 'components/hero-subheader';
@import 'components/micro-nav';
@import 'components/nav-grid';
@import 'components/page-nav';
@import 'components/sections-list';
@import 'components/site-footer';
@import 'components/site-header';
@import 'components/social-list';
@import 'components/title-anchor-link';

/* ----- Themes ----- */
@import 'themes/green';
@import 'themes/red';
@import 'themes/yellow';
@import 'themes/purple';


.hero__author-image {
  max-width: 44px;
  border-radius: 50%;
  margin-bottom: 17px;
}

.hero__author-name {
  display: block;
  color: #fff;
  font-family: GothamMedium,Gotham,Arial,sans-serif;
  letter-spacing: 3px;
  font-size: 15px;
  font-size: .9375rem;
  border-bottom: none;
}

.article-tags{
  flex-wrap: wrap;
  display: flex;
  list-style: none;
  margin-bottom: 0px;
}

.article-tags li:before{
  content: none !important;
}
.article-tags li{
  content: none !important;
  padding: 0px;
  margin-right: 15px;
}

.article-tags li{
  color: #E82F3A;
}

.nav-grid__content{
  background-size: cover !important;
  height: 220px !important;
}

.nav-grid__content h2{
      text-align: center;
}

@media (max-width: 768px) {
  .hero-subheader-home {
      background-image: none !important
  }
}

.partner{
  padding: 20px;
  border: 1px solid #CCC;
  position: relative;
  margin: 0 0 20px;
  border-bottom: 1px dashed #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
  background: #fff;

  .company{
    margin-top: 0;
    border-bottom: none;
    display: inline-block;
  }

  .fulltime {
    font-size: 12px;
    margin-top: 5px;
    padding: 2px 10px;
    text-align: right;
    border-radius: 20px;
    float: right;
    text-transform: uppercase;
    display: inline-flex;
    max-width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    border-radius: 3px;
    border-image: initial;
    background: rgb(55, 57, 66);
  }

  .type {
    display: inline-block;
    margin-top: 5px;
    padding: 2px 10px;
    text-align: right;
    border-radius: 20px;
    float: right;
    text-transform: uppercase;
  }

  img.logo-company {
    float: left;
    width: 80px;
    height: 80px;
    border: 2px solid #eee;
    overflow: hidden;
    margin-right: 1rem;
    object-fit: contain;
    padding: 5px;
  }

  .info-details {
      border-left: 1px solid #CCC;
  }

}

.company-nav {
  argin-bottom: 1.5rem;
  transition: all 0.3s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;
  height: 4.125rem;
  min-height: auto;
  position: relative;
  background-color: rgb(255, 255, 255);
  .principal {
    border-bottom: 1px solid rgb(230, 230, 230);
    position: relative;
    z-index: 99;
    background-color: inherit;
    height: 4.125rem;
    backface-visibility: hidden;
    transition: all 0.3s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;

    .content{
      position: relative;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1170px;
      //padding: 0px 1.5rem;

      .logo {
        backface-visibility: hidden;
        transform: translateY(0px);
        position: absolute;
        //left: 1.5rem;
        bottom: calc(-1.5rem);
        width: 12.5rem;
        height: 12.5rem;
        box-shadow: rgba(0, 0, 0, 0.1) 2px 5px 20px;
        border-radius: 3px;
        transition: all 0.3s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;
        background-color: white;
        object-fit: contain;
      }

      .infos{
        margin-left: 200px;
        position: relative;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-flex: 1;
        flex-grow: 1;
        height: 100%;
        padding: 0px;

        ul{
          position: relative;
          z-index: 3;
          display: inline-flex;
          -webkit-box-pack: start;
          justify-content: flex-start;
          height: 100%;
          margin: 20px;
          font-size: 1.2rem;
          li{
            color: rgb(55, 57, 66);
            padding-left: 0px;
            position: relative;
            margin-right: 0.8125rem;
            min-width: auto;
            padding: 0px 0.8125rem;
            &::before {
              content: none;
            }
            a{
              position: relative;
              height: 100%;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              cursor: pointer;
              font-size: inherit;
              color: inherit;
              font-weight: 600;
              text-transform: inherit;
              white-space: nowrap;
              text-overflow: ellipsis;
              background: none;
              border-width: initial;
              border-style: none;
              border-color: initial;
              border-image: initial;
              padding: 0px;
              outline: none;
              transition: all 0.3s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;
              overflow: hidden;
              &:hover::after {
                  opacity: 1;
                  transform: translateX(0px);
              }
              &::after {
                  position: absolute;
                  left: 0px;
                  bottom: 0px;
                  width: 100%;
                  height: 3px;
                  background-color: rgb(135, 136, 141);
                  opacity: 0;
                  transform: translateX(-10px);
                  content: " ";
                  border-radius: 3px;
                  transition: all 0.3s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 670px){
  .company-nav{
    display: none;
  }
}

.article-separator{
  height: 4px;
  background: #0092FF;
}

.chartAreaWrapper {
    height: 50px;
    /* border-left:1px solid #cae0e7; */
    /* border-right:1px solid #cae0e7; */
    margin-bottom: 25px;
    position: relative;
}

.chartAreaWrapper .sum {
    position: absolute;
    right: 0;
    width: 30px;
    top: -23px;
    color: #2980b9;
    font-size: 14px;
    font-weight: 500
}

.chartAreaWrapper .chartAreaContainer {
    top: 15%;
    height: 70%;
    width: 100%;
    position: relative;
    line-height: 240%;
}

.chartAreaWrapper .chartAreaContainer .Grid {
    width: 0;
    float: left;
    height: 100%;
    color: #fff;
    text-align: center;
    font-size: 14px;
}

.chartAreaWrapper .chartAreaContainer .Grid>.value {
    margin-top: 2px
}

.chartAreaWrapper .chartAreaContainer .grid {
    width: 0;
    float: left;
    height: 100%;
    position: relative;
    text-align: center;
    opacity: 0;
    font-size: 14px;
    color: #fff
}

.chartAreaWrapper .chartAreaContainer .grid .value {
    margin-top: 8px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden
}

.chartAreaWrapper .chartAreaContainer .grid-transparent .value {
    color: #104f79
}

.chartAreaWrapper .chartAreaContainer .multiGrid[data-value="100"]:after,.chartAreaWrapper .chartAreaContainer .multiGrid[data-value="100"]:before {
    content: none
}

.chartAreaWrapper .chartAreaContainer .multiGrid {
    width: 0;
    float: left;
    height: 100%;
    position: relative
}

.chartAreaWrapper .chartAreaContainer .multiGrid>.value {
    margin-top: 8px;
    color: #fff
}

.chartAreaWrapper .chartAreaContainer .multiGrid:after {
    content: "";
    height: 9px;
    right: 0;
    width: 1px;
    background: #cae0e7;
    bottom: -9px;
    position: absolute
}

.chartAreaWrapper .chartAreaContainer .multiGrid .subGrid {
    width: 50%;
    height: 8px;
    bottom: -13px;
    position: absolute
}

.chartAreaWrapper .chartAreaContainer .multiGrid .subGrid .value {
    position: absolute;
    right: -30px;
    width: 25px;
    top: -3px;
    margin-top: 0;
    color: #104f79;
    font-size: 10px
}

.chartAreaWrapper .chartAreaContainer .multiGrid.third-service-focus:before {
    content: "";
    height: 18px;
    left: -1px;
    width: 1px;
    background: #cae0e7;
    bottom: -18px;
    position: absolute
}

.chartAreaWrapper .chartAreaContainer .multiGrid.third-service-focus:after {
    content: "";
    height: 18px;
    right: 0;
    width: 1px;
    background: #cae0e7;
    bottom: -18px;
    position: absolute
}

.chartAreaWrapper .chartAreaContainer.third-service-focus:after {
    content: "";
    height: 18px;
    right: -1px;
    width: 1px;
    background: #cae0e7;
    bottom: -18px;
    position: absolute
}

.chartAreaWrapper .value-outside {
    top: 20px;
    position: relative;
    font-size: 16px
}

.chartAreaWrapper.service-focus-chart {
    width: 100%;
    margin-bottom: 15px
}

.chartAreaWrapper.type-small {
    height: 30px
}

.chartAreaWrapper.type-small .chartAreaContainer {
    top: 25%;
    height: 50%
}

.chartAreaWrapper.service-focus-graph {
    width: 100%;
    height: 40px;
    margin-bottom: 15px
}

.chartAreaWrapper.service-focus-graph .chartAreaContainer {
    top: 25%;
    height: 50%
}

@media (min-width: 768px) {
    .chartAreaWrapper {
        width:90%
    }

    .chartAreaWrapper .sum {
        right: -40px;
        top: 17px
    }
}

.chartWrapper {
    height: 42px;
    overflow: hidden;
    padding-bottom: 20px;
    margin-bottom: 20px
}

.chartWrapper,.graph_container {
    border-left: 1px solid #cae0e7;
    border-right: 1px solid #cae0e7
}

.graph_container {
    display: block;
    height: 40px;
    padding-top: 10px
}

.ability-list,.legend-list,.service-focus {
    padding: 0;
    list-style: none;
    width: 100%
}

.ability-list .item,.legend-list .item,.service-focus .item {
    text-align: left
}

.ability-list li,.legend-list li,.service-focus li {
    display: inline-block;
    font-size: 14px;
    color: #6d6e71;
    padding: 0 20px 0 30px;
    position: relative;
    box-sizing: border-box;
    max-width: 33%;
    vertical-align: top
}

@media screen and (max-width: 768px) {
    .ability-list li.item,.legend-list li.item,.service-focus li.item {
        width:auto;
        display: none;
        text-align: center
    }

    .ability-list li.item.active,.legend-list li.item.active,.service-focus li.item.active {
        display: block
    }
}

.ability-list li span,.legend-list li span,.service-focus li span {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: #ccc;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -1px;
    position: absolute;
    top: 0px;
    left: 0
}

.ability-list li:last-child,.legend-list li:last-child,.service-focus li:last-child {
    padding-right: 0
}

.legend-list li {
    width: auto;
    max-width: none
}


.lhbYog.company-stats {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    border-image: initial;
}
.lhbYog:last-child {
    margin-bottom: 0px;
}
.lhbYog {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 10px;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;
}

.company-stats .btYjPU {
    padding: 0px;
}

.summary-ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: -1px;
}

.summary-li {
    position: relative;
    width: 50%;
    z-index: 2;
    text-align: center;
    flex: 1 1 auto;
    padding: 0px 0px 10px 0px;
    background-color: #f2f8ff;
    border: 1px solid #deedff;
    margin: 0px;
}

.summary-li::before{
  content: none;
}

.summary-label  {
    color: #00244f;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    span{
          font-weight: bold;
    }
}

.summary-value {
    display: inline-block;
    color: rgb(0, 194, 154);
    font-family: "Montserrat",sans-serif;
    font-size: 1.133em;
    font-weight: 500;
}

.company_name_container{
  .logo{
    display: none;
  }
  @media (max-width: 670px){
    .logo {
      display: block;
      width: 9rem;
      height: 9rem;
      margin: 0px auto 1rem;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 3px;
      object-fit: contain;
      background-color: white;
    }
  }
}

.boolo-custom-hits{
  color: inherit !important;
}

.booo-custom-list{
  margin: unset !important;
  display: unset !important;
  flex-wrap: unset !important;
}

.booo-custom-hits-item{
  margin: unset !important;
  display: unset !important;
  flex-wrap: unset !important;
  padding: unset !important;
  width: unset !important;
  border: unset !important;
  box-shadow: unset !important;
}

#search-expertises, #search-services, #search-location, #search-partnership  {
  padding: 20px;
  border: 1px solid #CCC;
  position: relative;
  margin: 0 0 20px;
  border-bottom: 1px dashed #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 2px 0 0 rgba(0,0,0,0.1);
  background: #fff;
}

.ais-RefinementList-list li:before {
    content: none !important;
}

.ais-RefinementList-list li {
  padding-left: 1.2rem !important;
}


#search-searchbar .ais-SearchBox-form{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  padding-left: 40px;
  padding-right: 136px;
  background-color: #f5f5fa;
  color: inherit;
  border-radius: 4px;
}

#search-searchbar .ais-SearchBox-input{
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #5d6494;
  outline: 0;
  border: none;
}



.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.contact {
  position: relative;
  margin: 0 auto;
  color: #fff;
  background-image: url(/uploads/bg_blue.svg);
  background-size: cover;
  min-height: 200px;
}

.contact img {
    float: left
}

.contact h2 {
    font-size: 1.8rem;
    margin: 17px 0 0;
    padding-left: 100px;
    color: #fff
}

.contact a {
  margin-bottom: 10px;
  padding: 20px 40px;
  text-transform: uppercase;
  background-color: #c00;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 5px;
  border-bottom: 3px solid #900;
  display: inline-block;
  margin-top: 15px;
}

.contact a:hover {
    text-decoration: none;
    opacity: .9
}

.control-box, .tooltip-inner, .info-details{
  text-transform: capitalize;
}
