/**
 * Site header (components/_site-header.scss)
 */

/* ----- General ----- */
.site-header {
	position: absolute;
	width: 100%;
	height: 5.625rem;
	//border-bottom: 1px solid rgba($color-dark-blue, .15);
}

/* ----- Navigation ----- */
.site-header__logo_white {
	@include link-reset;
	display: block;
	float: left;
	color: $color-white;
	font-family: $font-family-secondary;
	font-weight: bold;
	font-size: 1.6em;
	line-height: 5.625rem;

	img {
		height: calc(5.625rem - 1px);
	}
}

.site-header__logo_black {
	@extend .site-header__logo_white;
	color: $color-dark-blue;
}

/* ----- Navigation ----- */
.site-header__nav_white {
	@include list-horizontal;
	@include clearfix;
	float: right;
	margin-bottom: 0;
	color: $color-white;

	li {
		+ li {
			margin-left: 2.5rem;
		}
	}

	a {
		@include link-reset;
		display: block;
		font-size: 1em;
		font-weight: 600;
		font-family: $font-family-secondary;
		line-height: calc(5.625rem - 1px);
		text-transform: uppercase;
		//border-bottom: 1px solid;
		border-color: transparent;
		transition: border-color .2s;

		&:hover,
		&:focus,
		&:active {
			border-bottom: 3px solid;
			border-color: $color-dark-blue;
		}

		&.active {
			border-bottom: 3px solid;
			border-color: $color-dark-blue;
		}
	}
}

/* ----- Offcanvas ----- */
.offcanvas {
	overflow: hidden;
	width: 100%;
	height: 100%;
	text-align: center;
	background-color: $color-white;
	position: fixed;
	top: 0;
	bottom: 0;
	opacity: 0;
	visibility: hidden;
	@include translate(0, -1.875rem);
	transition: transform .2s, opacity .2s, visibility .2s;
	z-index: $z-index-offcanvas;

	.offcanvas-expanded & {
		opacity: 1;
		visibility: visible;
		@include translate(0, 0);
	}
}

.site-header__nav_black {
	@extend .site-header__nav_white;
	color: $color-dark-blue;
}

/* ----- Offcanvas navigation ----- */
.offcanvas__nav {
	height: 100%;
	overflow: scroll;
	padding-top: 5rem;
	padding-bottom: 5rem;
	-webkit-overflow-scrolling: touch;

	li {
		padding: 0;

		&::before {
			display: none;
		}

		+ li {
			margin-top: 1.25rem;
		}
	}

	a {
		@include link-reset;
		color: $color-dark-blue;
		font-family: $font-family-secondary;
		text-transform: uppercase;
	}
}

/* ----- Offcanvas toggle ----- */
.offcanvas-toggle {
	height: calc(5.625rem - 1px);
	width: 5.625rem;
	cursor: pointer;
	position: fixed;
	right: 0;
	top: 0;
	@include translate3d(0, 0, 0);
	z-index: $z-index-offcanvas-toggle;

	span {
		display: block;
		height: 2px;
		width: 1.875rem;
		background-color: $color-dark-blue;
		position: absolute;
		left: 1.875rem;
		transition: top .2s, transform .2s, opacity .2s;

		&:first-child {
			top: 2.125rem;
		}

		&:nth-child(2) {
			top: 2.6875rem;
		}

		&:last-child {
			top: 3.25rem;
		}
	}

	.offcanvas-expanded & {
		span {
			&:first-child,
			&:last-child {
				top: 2.6875rem;
			}

			&:first-child {
				@include rotate(45);
			}

			&:last-child {
				@include rotate(-45);
			}

			&:nth-child(2) {
				opacity: 0;
			}
		}
	}
}
