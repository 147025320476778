/**
 * Example (components/_example.scss)
 */

/* ----- General ----- */
.example {
	overflow: hidden;
	color: $color-white;
	background-color: $color-pre-border;
	padding: .625rem .625rem .625rem 1.4375rem;

	&::before {
		content: 'Example';
		display: inline-block;
		margin-top: .25rem;
		margin-bottom: .25rem;
		text-transform: uppercase;
	}

	a {
		@include link-reset;
		display: block;
		float: right;
		color: $color-dark-blue;
		font-size: .875em;
		background-color: $color-white;
		border-radius: 1.25rem;
		padding: .25rem 1.25rem .3125rem 1.25rem;
		transition: background-color .2s;

		&:hover,
		&:focus,
		&:active {
			background-color: $color-yellow;
		}

		&::after {
			@extend .icon;
			content: '\e922';
			display: inline-block;
			position: relative;
			top: .125rem;
			margin-left: .5rem;
		}
	}
}
