/**
 * Yellow (themes/_yellow.scss)
 */

/* ----- Hero subheader ----- */
.yellow {
	.hero-subheader {
		background-color: $color-theme-yellow;
	}
}

/* ----- Nav grid ----- */
.yellow {
	.nav-grid__item {
		background-color: $color-theme-yellow;

		&:hover,
		&:active,
		&:focus {
			background-color: $color-theme-yellow;
		}
	}
}

/* ----- Section ----- */
.yellow {
	.section--darker-theme-color-bg {
		background-color: darken($color-theme-yellow, 3%);
	}
}

/* ----- Page nav ----- */
.yellow {
	.page-nav {
		background-color: $color-theme-yellow;
	}
}

/* ----- Error 404 ----- */
.yellow {
	&.error-404 {
		background-color: $color-theme-yellow;
	}
}
