/**
 * Error 404 (objects/_error-404.scss)
 */

/* ----- General ----- */
.error-404 {
	background-color: $color-theme-blue;
	text-align: center;

	.align-container {
		height: 100vh;
	}
}

/* ----- Title ----- */
.error-404__title {
	color: $color-dark-blue;
	font-weight: normal;
	font-size: $font-size-h3;
	margin-top: 0;

	@media (min-width: $screen-sm-min) {
		font-size: $font-size-h1;
	}
}
