/**
 * Green (themes/_green.scss)
 */

/* ----- Hero subheader ----- */
.green {
	.hero-subheader {
		background-color: $color-theme-green;
	}
}

/* ----- Nav grid ----- */
.green {
	.nav-grid__item {
		background-color: $color-theme-green;

		&:hover,
		&:active,
		&:focus {
			background-color: $color-theme-green;
		}
	}
}

/* ----- Section ----- */
.green {
	.section--darker-theme-color-bg {
		background-color: darken($color-theme-green, 3%);
	}
}

/* ----- Page nav ----- */
.green {
	.page-nav {
		background-color: $color-theme-green;
	}
}

/* ----- Error 404 ----- */
.green {
	&.error-404 {
		background-color: $color-theme-green;
	}
}
