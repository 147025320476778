/**
 * Clearfix (tools/_clearfix.scss)
 */

/* ----- General ----- */
@mixin clearfix {
	&::before,
	&::after {
		content: ' ';
		display: table;
	}

	&::after {
		clear: both;
	}
}
