/**
 * Sections list (components/_sections-list.scss)
 */

/* ----- General ----- */
.sections-list {
	float: left;
	margin-top: 1.25rem;
}

/* ----- Wrapper ----- */
.sections-list-wrapper {
	width: 100%;
}

/* ----- Affix ----- */
.sections-list.affix {
	position: fixed;
	top: 0;
}

.sections-list.affix-bottom {
	position: absolute;
}

/* ----- List ----- */
.sections-list {
	ul {
		ul {
			margin-top: .625rem;
			margin-bottom: 0;
		}

		li {
			margin-bottom: .625rem;
			border-left: 3px solid #eee;
			transition: border .2s;

			&.active {
				border-left-color: $color-dark-blue;
			}

			&.has-submenu {
				padding-right: 1.25rem;

				&::after {
					@extend .icon;
					content: '\e91d';
					color: #bdbdbd;
					display: inline-block;
					font-size: 1.25rem;
					position: absolute;
					right: 0;
					top: .3125rem;
					transition: color .2s;
				}

				> ul {
					display: none;
				}

				&.active {
					&::after {
						color: $color-dark-blue;
					}

					> ul {
						display: block;
					}
				}
			}

			&::before {
				display: none;
			}

			a {
				@include link-reset;
			}
		}
	}
}
