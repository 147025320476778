/**
 * Micro nav (components/_micro-nav.scss)
 */

/* ----- General ----- */
.micro-nav {
	color: $color-white;
	background-color: $color-dark-blue;
	padding: .9375rem 0;
	text-align: center;

	@media (min-width: $screen-sm-min) {
		text-align: left;
	}
}

/* ----- Back ----- */
.micro-nav__back {
	@include link-reset;
	display: inline-block;
	font-weight: 500;
	font-family: $font-family-secondary;
	text-transform: uppercase;

	&:hover,
	&:focus,
	&:active {
		.icon {
			@include translate(-.3125rem, 0);
		}
	}

	.icon {
		display: inline-block;
		margin-right: .5rem;
		position: relative;
		bottom: -.125rem;
		transition: transform .2s;
	}
}
