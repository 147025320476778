/**
 * Social list (components/_social-list.scss)
 */

/* ----- General ----- */
.social-list {
	margin-bottom: 0;

	li {
		display: inline-block;
		padding: 0;

		+ li {
			margin-left: 1.25rem;
			margin-bottom: 1.25rem;
		}

		&::before {
			display: none;
		}
	}
}

/* ----- Item ----- */
.social-list__item {
	@include link-reset;
	display: block;
	color: $color-social-item;
	font-size: 1.875em;
	line-height: 1;
	transition: color .2s;

	&:hover,
	&:focus,
	&:active {
		&.social-list__item--behance {
			color: #1769ff;
		}

		&.social-list__item--bitbucket {
			color: #205081;
		}

		&.social-list__item--codepen {
			color: #494949;
		}

		&.social-list__item--dribbble {
			color: #ea4c89;
		}

		&.social-list__item--dropbox {
			color: #007ee5;
		}

		&.social-list__item--facebook {
			color: #3b5998;
		}

		&.social-list__item--github {
			color: #333;
		}

		&.social-list__item--gitlab {
			color: #fc6d26;
		}

		&.social-list__item--google-plus {
			color: #dd4b39;
		}

		&.social-list__item--gulp {
			color: #DA4648;
		}

		&.social-list__item--instagram {
			color: #e1306c;
		}

		&.social-list__item--kickstarter {
			color: #2bde73;
		}

		&.social-list__item--linkedin {
			color: #0077b5;
		}

		&.social-list__item--medium {
			color: #00ab6c;
		}

		&.social-list__item--meetup {
			color: #e0393e;
		}

		&.social-list__item--pocket {
			color: #ef4056;
		}

		&.social-list__item--producthunt {
			color: #da552f;
		}

		&.social-list__item--reddit {
			color: #ff4500;
		}

		&.social-list__item--skype {
			color: #00aff0;
		}

		&.social-list__item--slack {
			color: #3eb991;
		}

		&.social-list__item--stackoverflow {
			color: #f48024;
		}

		&.social-list__item--trello {
			color: #0079bf;
		}

		&.social-list__item--tumblr {
			color: #35465c;
		}

		&.social-list__item--twitter {
			color: #1da1f2;
		}

		&.social-list__item--youtube {
			color: #cd201f;
		}
	}

	.icon {
		display: block;
	}
}
