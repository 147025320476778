/**
 * Responsive visibility (tools/_responsive-visibility.scss)
 */

/* ----- Visibility ----- */
@mixin responsive-visibility($parent) {
	#{$parent} {
		display: block !important;
	}
	table#{$parent}  { display: table !important; }
	tr#{$parent}     { display: table-row !important; }
	th#{$parent},
	td#{$parent}     { display: table-cell !important; }
}

/* ----- Invisibility ----- */
@mixin responsive-invisibility($parent) {
	#{$parent} {
		display: none !important;
	}
}
