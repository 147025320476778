/**
 * Site footer (components/_site-footer.scss)
 */

/* ----- General ----- */
.site-footer {
	background-color: #2d3038;
	padding: 3.75rem 0 (3.75rem - 1.25rem) 0;

	hr {
		width: 5.625rem;
	}
}

/* ----- Logo ----- */
.site-footer__logo {
	@include link-reset;
	display: inline-block;
	color: #FFF;
	font-family: $font-family-secondary;
	font-size: $font-size-h3;
	font-weight: 700;
	vertical-align: top;
	margin: 0 0 1.25rem 0;

	img {
		display: block;
		width: 15.625rem;
	}
}

/* ----- Copyright ----- */
.site-footer__copyright {
	color: $color-footer-copyright;
	font-size: .875em;
	line-height: $line-height-base;
}
