/**
 * Icons (objects/_icons.scss)
 */

/* ----- General ----- */
@font-face {
	font-family: 'icons';
	src: url('../fonts/icons/icons.ttf') format('truetype'),
		 url('../fonts/icons/icons.woff') format('woff'),
		 url('../fonts/icons/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon {
	font-family: 'icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
}

/* ----- List of icons ----- */
.icon--behance {
	&::before {
		content: '\e900';
	}
}

.icon--bitbucket {
	&::before {
		content: '\e901';
	}
}

.icon--codepen {
	&::before {
		content: '\e902';
	}
}

.icon--dribbble {
	&::before {
		content: '\e903';
	}
}

.icon--dropbox {
	&::before {
		content: '\e904';
	}
}

.icon--facebook {
	&::before {
		content: '\e905';
	}
}

.icon--github {
	&::before {
		content: '\e906';
	}
}

.icon--gitlab {
	&::before {
		content: '\e907';
	}
}

.icon--google-plus {
	&::before {
		content: '\e908';
	}
}

.icon--gulp {
	&::before {
		content: '\e909';
	}
}

.icon--instagram {
	&::before {
		content: '\e90a';
	}
}

.icon--kickstarter {
	&::before {
		content: '\e90b';
	}
}

.icon--linkedin {
	&::before {
		content: '\e90c';
	}
}

.icon--medium {
	&::before {
		content: '\e90d';
	}
}

.icon--meetup {
	&::before {
		content: '\e90e';
	}
}

.icon--pocket {
	&::before {
		content: '\e90f';
	}
}

.icon--producthunt {
	&::before {
		content: '\e910';
	}
}

.icon--reddit {
	&::before {
		content: '\e911';
	}
}

.icon--skype {
	&::before {
		content: '\e912';
	}
}

.icon--slack {
	&::before {
		content: '\e913';
	}
}

.icon--stackoverflow {
	&::before {
		content: '\e914';
	}
}

.icon--trello {
	&::before {
		content: '\e915';
	}
}

.icon--tumblr {
	&::before {
		content: '\e916';
	}
}

.icon--twitter {
	&::before {
		content: '\e917';
	}
}

.icon--youtube {
	&::before {
		content: '\e918';
	}
}

.icon--arrow-down {
	&::before {
		content: '\e919';
	}
}

.icon--arrow-left {
	&::before {
		content: '\e91a';
	}
}

.icon--arrow-right {
	&::before {
		content: '\e91b';
	}
}

.icon--arrow-up {
	&::before {
		content: '\e91c';
	}
}

.icon--chevron-down {
	&::before {
		content: '\e91d';
	}
}

.icon--chevron-left {
	&::before {
		content: '\e91e';
	}
}

.icon--chevron-right {
	&::before {
		content: '\e91f';
	}
}

.icon--chevron-up {
	&::before {
		content: '\e920';
	}
}

.icon--home {
	&::before {
		content: '\e921';
	}
}

.icon--maximize {
	&::before {
		content: '\e922';
	}
}

.icon--x {
	&::before {
		content: '\e923';
	}
}
