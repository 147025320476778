/**
 * Hero subheader (components/_hero-subheader.scss)
 */

/* ----- General ----- */

.hero-subheader-home {
	 background: #ceeaff url('/crm-training/assets/images/header_mountain.svg') no-repeat 49.5vw bottom !important;
	 color: $color-dark-blue;
	 padding: 9.375rem 0 3.4375rem 0;
}

.hero-subheader {
	color: $color-white;

	@media (min-width: $screen-sm-min) {
		padding: 9.375rem 0 (10.3125rem - 1.25rem) 0;
	}

	.btn {
		margin-right: .9375rem;
	}

	.btn--github,
	.btn--w-arrow-down {
		@extend .btn--dark;
	}

	.site-header + & {
		padding: 9.375rem 0 (4.6875rem - 1.25rem) 0;

		@media (min-width: $screen-sm-min) {
			padding: 15rem 0 (10.3125rem - 1.25rem) 0;
		}
	}
}

.hero-subheader--before-out {
	@media (min-width: $screen-sm-min) {
		padding: 14rem 0 (15.3125rem - 1.25rem) 0;
	}
}

/* ----- Title ----- */
.hero-subheader__title {
	margin-top: 0;
}

/* ----- Title ----- */
.hero-subheader__desc {
	margin-bottom: 1.875rem;
}

/* ----- Author ----- */
.hero-subheader__author {
	border-left: 2px solid;
	padding-left: 2.5rem;

	.btn {
		margin-bottom: 0;
	}
}

.hero-subheader__author-title {
	font-size: $font-size-h5;
}

/* ----- More ----- */
.hero-subheader__btn-more {
	color: inherit;
}
